import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import MediaQuery from 'react-responsive';
import { Link, NavLink, withRouter } from 'react-router-dom';
import logo from '../../../assets/images/tikrinu.svg';
import Button from '../../shared/Button';
import FbButton from '../../core/FbButton';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check.svg';
import { ReactComponent as HandIcon } from '../../../assets/icons/hand.svg';
import { ReactComponent as HeartIcon } from '../../../assets/icons/heart.svg';
import { ReactComponent as MoonIcon } from '../../../assets/icons/moon.svg';
import { ReactComponent as CrossIcon } from '../../../assets/icons/cross.svg';
import { TestConsumer } from '../../Test.context';
import Search from './Search';
import './Header.scss';

class Header extends React.Component {
  state = {
    isMenuOpen: false,
    isSearchOpen: false,
    searchQuery: ''
  };

  searchInputRef = React.createRef();

  navLinks = [
    {
      name: 'Laukiuosi',
      icon: () => <CheckIcon />,
      link: '/laukiuosi'
    },
    {
      name: 'Noriu pastoti',
      icon: () => <HeartIcon />,
      link: '/noriu-pastoti'
    },
    {
      name: 'Esu sveika',
      icon: () => <HandIcon />,
      link: '/esu-sveika'
    },
    {
      name: 'Man menopauzė',
      icon: () => <MoonIcon />,
      link: '/man-menopauze'
    }
  ];

  secondaryNavLinks = [
    [
      {
        name: 'Apie',
        link: '/apie'
      },
      {
        name: 'Klinikos',
        link: '/klinikos'
      },
      {
        name: 'Tyrimų sąrašas',
        link: '/tyrimai-ir-prevencija'
      }
    ],
    [
      {
        name: 'Privatumo politika',
        link: '/privatumo-politika'
      },
      {
        name: 'Slapukai',
        link: '/slapukai'
      },
      {
        name: 'Teisinė informacija',
        link: '/teisine-informacija'
      }
    ]
  ];

  componentDidMount() {
    this.props.history.listen(() => this.toggleMenu(true));
  }

  toggleMenu = (close = false) => {
    this.setState(prevState => {
      const $body = document.querySelector('body');
      const isMenuOpen = close ? false : !prevState.isMenuOpen;

      if (close) {
        $body.classList.remove('fixed');
      } else {
        $body.classList.toggle('fixed');
      }

      return { isMenuOpen };
    });
  };

  toggleSearch = () => {
    this.setState(
      prevState => ({
        isSearchOpen: !prevState.isSearchOpen
      }),
      () => {
        if (this.state.isSearchOpen) {
          this.searchInputRef.current.focus();
        } else {
          this.searchInputRef.current.blur();
        }
      }
    );
  };

  closeSearch = () => {
    if (this.state.isSearchOpen) {
      this.setState(
        {
          isSearchOpen: false
        },
        () => {
          this.searchInputRef.current.blur();
        }
      );
    }
  };

  submitSearchForm = () => {
    const query = this.state.searchQuery;

    if (query) {
      this.setState(
        {
          isSearchOpen: false,
          searchQuery: ''
        },
        () => {
          this.props.history.push(`/paieska?=${query}`);
        }
      );
    }
  };

  searchQueryChange = e => {
    this.setState({
      searchQuery: e.target.value
    });
  };

  render() {
    const PrimaryNavLinks = () => (
      <Fragment>
        {this.navLinks.map((l, i) => (
          <span key={i}>
            <NavLink to={l.link} className="nav-link" activeClassName="active">
              {l.icon()}
              {l.name}
            </NavLink>
          </span>
        ))}
      </Fragment>
    );

    return (
      <Fragment>
        <header>
          <div className="container">
            <TestConsumer>
              {({ changeStep }) => (
                <div
                  className="logo"
                  onClick={() => {
                    this.props.history.push('/');
                    changeStep(0);
                  }}
                >
                  <img src={logo} alt="tikrinu.lt" />
                </div>
              )}
            </TestConsumer>
            <div className="side">
              <nav>
                <MediaQuery query="(min-width: 1025px)">
                  <PrimaryNavLinks />
                </MediaQuery>
                <MediaQuery query="(min-width: 769px)">
                  <Search
                    isOpen={this.state.isSearchOpen}
                    toggleSearch={this.toggleSearch}
                    onSubmit={this.submitSearchForm}
                    onChange={this.searchQueryChange}
                    value={this.state.searchQuery}
                    innerRef={this.searchInputRef}
                    onCloseSearch={this.closeSearch}
                  />
                </MediaQuery>
                <Button burger onClick={() => this.toggleMenu()}>
                  MENU
                </Button>
              </nav>
            </div>
          </div>
        </header>
        <div
          className={`secondary-menu-window is-open-${this.state.isMenuOpen}`}
        >
          <div className="container">
            <div className="top-actions">
              <MediaQuery query="(max-width: 768px)">
                <FbButton to="https://fb.com" />
                <Search
                  isOpen={this.state.isSearchOpen}
                  toggleSearch={this.toggleSearch}
                  onSubmit={this.submitSearchForm}
                  onChange={this.searchQueryChange}
                  value={this.state.searchQuery}
                  innerRef={this.searchInputRef}
                  onCloseSearch={this.closeSearch}
                />
              </MediaQuery>
              <div className="close-button" onClick={() => this.toggleMenu()}>
                <CrossIcon />
              </div>
            </div>
            <MediaQuery query="(max-width: 1024px)">
              <nav className="top-menu">
                <PrimaryNavLinks />
              </nav>
              <hr />
            </MediaQuery>
            <nav className="primary">
              {this.secondaryNavLinks[0].map((l, i) => (
                <div key={i}>
                  <Link to={l.link}>{l.name}</Link>
                </div>
              ))}
            </nav>
            <hr />
            <nav className="secondary">
              {this.secondaryNavLinks[1].map((l, i) => (
                <div key={i}>
                  <Link to={l.link}>{l.name}</Link>
                </div>
              ))}
            </nav>
          </div>
        </div>
        <MediaQuery query="(min-width: 769px)">
          <div className="fb-button-holder">
            <FbButton to="https://fb.com" />
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

Header.propTypes = {
  history: PropTypes.object.isRequired
};

export default withRouter(Header);
