import React from 'react';
import PropTypes from 'prop-types';
import './RecommendationsList.scss';

class RecommendationsList extends React.Component {
  createMarkup = html => ({
    __html: html
  });

  render() {
    const { list, title, cols } = this.props;

    return (
      <div className="recommendations-list">
        {title && <h2>{title}</h2>}

        <div className={`list-holder col-${cols}`}>
          {list.map((research, i) => (
            <div className="list-item-holder" key={i}>
              <div className="list-item">
                <h3 className="title" style={{ marginTop: 20 }}>
                  {research.title}
                </h3>
                <div className="subtitle">{research.subtitle}</div>
                <div
                  className="description"
                  dangerouslySetInnerHTML={this.createMarkup(
                    research.description
                  )}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

RecommendationsList.propTypes = {
  list: PropTypes.array.isRequired,
  title: PropTypes.string,
  cols: PropTypes.number
};

export default RecommendationsList;
