import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as RepeatIcon } from '../../../../assets/icons/repeat.svg';
import { TestConsumer } from '../../../Test.context';

const Title = ({ data, type, onRepeatTest }) => {
  const getBarometerValue = type => {
    if (type === 'test-result-a') {
      return 0;
    }
    if (
      [
        'test-result-b',
        'test-result-c',
        'test-result-d',
        'test-result-e'
      ].includes(type)
    ) {
      return 31;
    }
    if ('test-result-f' === type) {
      return 64;
    }
    if ('test-result-g' === type) {
      return 100;
    }
  };

  return (
    <div className="test-results-title">
      <div className="side">
        <h1>{data.title}</h1>
        <p>{data.subtitle}</p>
      </div>
      <div className="side">
        <div className="results-barometer-holder">
          <div className="barometer-actions">
            <div className="label">Rizikos barometras</div>
            <TestConsumer>
              {({ changeStep }) => (
                <div
                  className="btn-repeat-test"
                  onClick={() => onRepeatTest(changeStep)}
                >
                  <RepeatIcon />
                  <span>
                    Pakartok <span>testą</span>
                  </span>
                </div>
              )}
            </TestConsumer>
          </div>
          <div className="barometer">
            <div
              className={`value value-${getBarometerValue(type)}`}
              style={{
                width: `calc(100% - ${getBarometerValue(type)}% + 4px)`
              }}
            >
              <div className="circle" />
            </div>
          </div>
          <div className="barometer-legend">
            <div>Nėra</div>
            <div>Padidėjusi</div>
            <div>Vidutinė</div>
            <div>Aukšta</div>
          </div>
        </div>
      </div>
    </div>
  );
};

Title.propTypes = {
  data: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  onRepeatTest: PropTypes.func.isRequired
};

export default Title;
