import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './TextField.scss';

const TextField = ({ placeholder, type, onChange, regular, ...rest }) => {
  const className = classNames('form-item', 'type-text', {
    'size-regular': regular
  });
  return (
    <div className={className}>
      <input
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        {...rest}
      />
    </div>
  );
};

TextField.defaultProps = {
  type: 'text'
};

TextField.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  regular: PropTypes.bool
};

export default TextField;
