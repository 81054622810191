import React from 'react';
import PropTypes from 'prop-types';
import LinkButton from '../../../shared/LinkButton';
import Loader from '../../../shared/Loader';
import urlBuilder from '../../../../utils/urlBuilder';
import './ReseachList.scss';

const createMarkup = data => ({ __html: data });
const ReseachList = ({ list, isFetching }) => (
  <div className="component-research-list">
    {isFetching ? (
      <Loader />
    ) : (
      list.map((item, index) => (
        <div className="list-item" key={index}>
          <div className="content-holder">
            <h3>{item.title}</h3>
            <div
              className="description"
              dangerouslySetInnerHTML={createMarkup(item.description)}
            />
            {((item.subgroups && !item.subgroups.length) ||
              !item.subgroups) && (
              <LinkButton
                to={`/tyrimai-ir-prevencija/${item.id}/${urlBuilder(
                  item.title
                )}`}
              >
                Informacija apie tyrimus
              </LinkButton>
            )}

            {item.subgroups &&
              item.subgroups.map((subgroup, key) => (
                <div
                  style={{
                    marginTop: 7
                  }}
                >
                  <LinkButton
                    simple
                    key={key}
                    to={`/tyrimai-ir-prevencija/${item.id}/${key}/${urlBuilder(
                      subgroup.title
                    )}`}
                  >
                    {subgroup.title}
                  </LinkButton>
                </div>
              ))}
          </div>
        </div>
      ))
    )}
  </div>
);

ReseachList.propTypes = {
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isFetching: PropTypes.bool
};

export default ReseachList;
