import React from 'react';
import { Element, scroller } from 'react-scroll';
import { Link } from 'react-router-dom';
import imgLaukiesi from '../../../../assets/images/img-laukiesi@2x.png';
import imgSveika from '../../../../assets/images/img-sveika@2x.png';
import imgPastoti from '../../../../assets/images/img-pastoti@2x.png';
import imgMenopauze from '../../../../assets/images/img-menopauze@2x.png';
import { ReactComponent as ArrowsDownIcon } from '../../../../assets/icons/arrows-down.svg';
import LinkButton from '../../../shared/LinkButton';
import './FeaturesList.scss';

class FeaturesList extends React.Component {
  featuresItems = [
    {
      path: '/laukiuosi',
      title: 'Laukiesi?',
      text:
        'Dabar tau reikia ypač daug dėmesio. Žinok, kokius svarbiausius tyrimus gali atlikti dėl savęs ir kūdikio.',
      imageUrl: imgLaukiesi
    },
    {
      path: '/esu-sveika',
      title: 'Jautiesi sveika?',
      text:
        'Puiku! Profilaktiškai tikrinkis sveikatą, kad jaustumeisi išties gerai.',
      imageUrl: imgSveika
    },
    {
      path: '/noriu-pastoti',
      title: 'Norėtum pastoti?',
      text: 'Tavęs laukia daug pokyčių. Būk jiems pasiruošusi.',
      imageUrl: imgPastoti
    },
    {
      path: '/man-menopauze',
      title: 'Prasidėjo menopauzė?',
      text: 'Perimk sveikatos kontrolę į savo rankas.',
      imageUrl: imgMenopauze
    }
  ];

  render() {
    const ButtonDown = ({ position = 'bottom' }) => (
      <div
        className={`arrow-button position-${position}`}
        onClick={() =>
          scroller.scrollTo(`element-${position}`, {
            duration: 600,
            delay: 0,
            smooth: 'easeInOutQuart'
          })
        }
      >
        <ArrowsDownIcon />
        <Element name={`element-${position}`} />
      </div>
    );

    return (
      <div className="component-features-list">
        <ButtonDown position="top" />
        <div className="container">
          <div className="content-holder">
            {this.featuresItems.map((f, i) => (
              <div className="list-item" key={i}>
                <div className="details-holder">
                  <h2>{f.title}</h2>
                  <div className="text">{f.text}</div>
                  <div className="link-holder">
                    <LinkButton to={f.path}>Pasitikrink</LinkButton>
                  </div>
                </div>
                <div className="image-holder">
                  <Link to={f.path}>
                    <img src={f.imageUrl} alt="" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
        <ButtonDown />
      </div>
    );
  }
}

export default FeaturesList;
