import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router-dom';

const TestContext = React.createContext();

class TestProvider extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    history: PropTypes.object.isRequired
  };

  state = {
    activeStep: 0,
    form: {
      age: '',
      stage1: null,
      stage2: null
    }
  };

  redirecToResults = type => {
    this.setState(
      {
        activeStep: 0,
        form: {
          age: '',
          stage1: null,
          stage2: null
        }
      },
      () => {
        this.props.history.push({
          pathname: '/rezultatai',
          state: { type }
        });
      }
    );
  };

  retrieveType = ({ age, stage1, stage2 }) => {
    if (age < 18 && age) {
      return 'test-result-a';
    } else if (age >= 18 && age <= 40) {
      if (stage1 === 1) {
        return 'test-result-e';
      } else if (stage1 === 2) {
        return 'test-result-f';
      } else if (stage1 === 0) {
        if (stage2 === 0) {
          return 'test-result-b';
        } else if (stage2 === 1) {
          return 'test-result-c';
        } else if (stage2 === 2) {
          return 'test-result-d';
        } else {
          return false;
        }
      } else {
        return false;
      }
    } else if (age > 40) {
      return 'test-result-g';
    } else {
      return false;
    }
  };

  changeStep = step => {
    const type = this.retrieveType(this.state.form);

    const proceedStepChange = () => {
      const nextStep =
        typeof step !== 'undefined' ? step : this.state.activeStep + 1;

      if (nextStep !== 0) {
        this.props.history.push({
          search: `?step=${nextStep}`
        });
      }

      this.setState(() => ({
        activeStep: nextStep
      }));
    };

    if (type) {
      this.redirecToResults(type);
    } else {
      proceedStepChange();
    }
  };

  updateForm = (value, name, changeStep) => {
    this.setState(
      prevState => ({
        form: Object.assign({}, prevState.form, {
          [name]: value
        })
      }),
      () => {
        if (changeStep) {
          this.changeStep();
        }
      }
    );
  };

  render() {
    const { children } = this.props;
    const { activeStep, form } = this.state;

    return (
      <TestContext.Provider
        value={{
          activeStep,
          form,
          changeStep: this.changeStep,
          updateForm: this.updateForm,
          retrieveType: this.retrieveType
        }}
      >
        {children}
      </TestContext.Provider>
    );
  }
}

export default withRouter(TestProvider);

export const TestConsumer = TestContext.Consumer;
