import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../shared/Button';

import './Cookies.scss';

class Cookies extends React.Component {
  state = {
    closed: false
  };

  render() {
    if (localStorage.getItem('cookies-accepted') || this.state.closed) {
      return null;
    }

    return (
      <div className="cookies">
        <div className="container">
          <div className="cookies--content">
            Norime informuoti, kad šioje svetainėje naudojami slapukai (angl.
            cookies). Paspaudę mygtuką „Sutinku“ arba naršydami toliau
            patvirtinsite savo sutikimą. Bet kada galėsite atšaukti savo
            sutikimą pakeisdami interneto naršyklės nustatymus ir ištrindami
            įrašytus slapukus.{' '}
            <Link to="/slapukai">Susipažinkite su privatumo politika</Link>.
          </div>

          <Button
            onClick={() => {
              localStorage.setItem('cookies-accepted', true);
              this.setState({
                closed: true
              });
            }}
            simple={true}
          >
            Sutinku
          </Button>
        </div>
      </div>
    );
  }
}

Cookies.defaultProps = {
  type: 'submit'
};

Cookies.propTypes = {};

export default Cookies;
