import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import './PageContainer.scss';

const PageContainer = ({ isFetching, content }) => (
  <div className="component-page-container">
    {isFetching ? <Loader /> : content()}
  </div>
);

PageContainer.defaultProps = {
  isFetching: false,
  content: () => {}
};

PageContainer.propTypes = {
  content: PropTypes.func,
  isFetching: PropTypes.bool
};

export default PageContainer;
