import React from 'react';
import PropTypes from 'prop-types';

const createMarkup = __html => ({ __html });

const ResultsItem = ({ onClick, title, description }) => (
  <div className="search-results-block">
    <h3 className="title" dangerouslySetInnerHTML={createMarkup(title)} />
    <div
      className="description"
      dangerouslySetInnerHTML={createMarkup(description)}
    />
    <div className="read-more" onClick={() => onClick()}>
      DAUGIAU
    </div>
  </div>
);

ResultsItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};

export default ResultsItem;
