import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import PageContainer from '../../shared/PageContainer';
import PageTitle from '../../shared/PageTitle';
import { BASE_URL } from '../../../utils/api';
import './TextPage.scss';

class TextPage extends React.Component {
  state = {
    isFetching: true,
    content: null
  };

  componentDidMount() {
    const { systemName } = this.props;

    fetch(`${BASE_URL}text-page/${systemName}`)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          this.setState({
            isFetching: false,
            content: res.data[0].text,
            title: res.data[0].title
          });
        }
      });
  }

  createMarkup = () => ({ __html: this.state.content });

  render() {
    const { isFetching, title } = this.state;
    return (
      <PageContainer
        isFetching={isFetching}
        content={() => (
          <Fragment>
            <div className="container">
              {Boolean(title) && <PageTitle>{title}</PageTitle>}
            </div>
            <div className="container-text-page">
              <div
                className="container"
                dangerouslySetInnerHTML={this.createMarkup()}
              />
            </div>
            {this.props.children && (
              <div className="container">{this.props.children}</div>
            )}
          </Fragment>
        )}
      />
    );
  }
}

TextPage.propTypes = {
  systemName: PropTypes.string.isRequired,
  children: PropTypes.node
};

export default TextPage;
