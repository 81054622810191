import React from 'react';
import { animateScroll } from 'react-scroll';
import Button from '../../../shared/Button';
import { TestConsumer } from '../../../Test.context';
import './StatsCta.scss';

class StatsCta extends React.Component {
  render() {
    return (
      <div className="component-stats-cta">
        <div className="container">
          <h2>
            Ar žinojai, kad net 99% gimdos kaklelio vėžinių atvejų galima
            užkirsti kelią išsityrus? Taip pat ir daugumai kitų ligų.
          </h2>
          <div className="cta-holder">
            <TestConsumer>
              {({ changeStep }) => (
                <Button
                  onClick={() => {
                    changeStep(1);
                    animateScroll.scrollToTop({
                      duration: 600,
                      delay: 0,
                      smooth: 'easeInOutQuart'
                    });
                  }}
                >
                  Užpildyk testą
                </Button>
              )}
            </TestConsumer>
            <div className="note">
              Kelios akimirkos ir sužinosi nuo kokių tyrimų pradėti, kad būtum
              sveikesnė.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StatsCta;
