import React, { Fragment } from 'react';
import Button from '../../../shared/Button';
import TextField from '../../../shared/TextField';
import step_0_img from '../../../../assets/images/step-0@2x.png';
import step_1_img from '../../../../assets/images/step-1@2x.png';
import step_2_img from '../../../../assets/images/step-2@2x.png';
import step_3_img from '../../../../assets/images/step-3@2x.png';
import { TestConsumer } from '../../../Test.context';
import './Hero.scss';

class Hero extends React.Component {
  stage1Options = [
    {
      name: 'Taip',
      value: 0
    },
    {
      name: 'Ne, bet planuoju',
      value: 1
    },
    {
      name: 'Ne',
      value: 2
    }
  ];

  stage2Options = [
    {
      name: 'I',
      value: 0
    },
    {
      name: 'II',
      value: 1
    },
    {
      name: 'III',
      value: 2
    }
  ];

  stepImage = [step_0_img, step_1_img, step_2_img, step_3_img];

  render() {
    return (
      <TestConsumer>
        {({ activeStep, form, changeStep, updateForm }) => (
          <div className="component-hero">
            <div className="container">
              <div className="hero-content">
                <div
                  className={`side content-holder active-step-${activeStep}`}
                >
                  {activeStep === 0 && (
                    <Fragment>
                      <h1>Labas. Kaip gerai, kad užsukai!</h1>
                      <div className="text">
                        Žinodami šį tą apie tave, galėsime padėti tau pažvelgti
                        į savo sveikatą atidžiau ir tikrinti tik tai, kas
                        svarbu. Norime tau užduoti kelis trumpus klausimus.
                      </div>
                      <div className="cta-holder">
                        <Button onClick={() => changeStep()}>
                          Užpildyk testą
                        </Button>
                        <div className="note">
                          Kelios akimirkos ir sužinosi nuo kokių tyrimų pradėti,
                          kad būtum sveikesnė.
                        </div>
                      </div>
                    </Fragment>
                  )}

                  {activeStep === 1 && (
                    <form
                      onSubmit={e => {
                        e.preventDefault();
                        changeStep();
                      }}
                    >
                      <h1>Kiek tau metų?</h1>
                      <div className="age-input-holder">
                        <TextField
                          placeholder="Įvesk savo amžių"
                          type="number"
                          onChange={e => updateForm(e.target.value, 'age')}
                        />
                        {Boolean(form.age) && (
                          <Button type="submit">Toliau</Button>
                        )}
                      </div>
                    </form>
                  )}

                  {activeStep === 2 && (
                    <Fragment>
                      <h1>Ar laukiesi?</h1>
                      <div className="buttons-group">
                        {this.stage1Options.map(o => (
                          <Button
                            key={o.value}
                            block
                            ghost
                            onClick={() => {
                              updateForm(o.value, 'stage1', true);
                            }}
                          >
                            {o.name}
                          </Button>
                        ))}
                      </div>
                    </Fragment>
                  )}

                  {activeStep === 3 && (
                    <Fragment>
                      <h1>Kuriame nėštumo trimestre esi?</h1>
                      <div className="buttons-group">
                        {this.stage2Options.map(o => (
                          <Button
                            key={o.value}
                            block
                            ghost
                            onClick={() => {
                              updateForm(o.value, 'stage2', true);
                            }}
                          >
                            {o.name}
                          </Button>
                        ))}
                      </div>
                    </Fragment>
                  )}
                </div>
                <div className="side image-holder">
                  <img src={this.stepImage[activeStep]} alt="" />
                </div>
              </div>
            </div>
          </div>
        )}
      </TestConsumer>
    );
  }
}

export default Hero;
