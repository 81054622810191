import React from 'react';
import PropTypes from 'prop-types';
import { BASE_URL } from '../../../utils/api';
import PageContainer from '../../shared/PageContainer';
import PageTitle from '../../shared/PageTitle';
import RecommendationsList from '../TestResults/RecommendationsList';
import './ResearchPage.scss';

class ResearchPage extends React.Component {
  state = {
    isFetching: true,
    data: {}
  };

  createMarkup = __html => ({ __html });

  componentDidMount() {
    const { id, subgroup } = this.props.match.params;

    fetch(`${BASE_URL}researches-and-preventions`)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          let rap = res.data.find(rapi => rapi.id === +id);

          if (!isNaN(parseInt(subgroup, 10))) {
            rap = rap.subgroups[+subgroup];
          }

          this.setState({
            isFetching: false,
            data: rap
          });
        }
      });
  }

  render() {
    const { isFetching, data } = this.state;

    return (
      <PageContainer
        isFetching={isFetching}
        content={() => (
          <div className="container-reasearch-page">
            <div className="container">
              <PageTitle
                belowTitle={() => (
                  <div
                    dangerouslySetInnerHTML={this.createMarkup(
                      data.description
                    )}
                  />
                )}
              >
                {data.title}
              </PageTitle>
              <RecommendationsList list={data.researches} />
            </div>
          </div>
        )}
      />
    );
  }
}

ResearchPage.propTypes = {
  match: PropTypes.object.isRequired
};

export default ResearchPage;
