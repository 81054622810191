import React from 'react';
import PropTypes from 'prop-types';
import { Map as M, Marker, GoogleApiWrapper } from 'google-maps-react';
import markerIcon from '../../../../assets/images/marker.png';
import './Map.scss';

class Map extends React.Component {
  render() {
    const { lat, lng } = this.props;

    if (!lat || !lng) {
      return null;
    }

    return (
      <div className="component-map">
        <M google={this.props.google} zoom={16} initialCenter={{ lat, lng }}>
          <Marker
            position={{ lat, lng }}
            icon={{
              url: markerIcon,
              scaledSize: { width: 48, height: 57 }
            }}
          />
        </M>
      </div>
    );
  }
}

// componentDidMount() {
//   const google = window.google;
//   const geocoder = new google.maps.Geocoder();
//   const address = this.props.address + ', Vilnius, Lietuva';
//
//   geocoder.geocode(
//     {
//       address
//     },
//     function(results, status) {
//       if (status == google.maps.GeocoderStatus.OK) {
//         if (status != google.maps.GeocoderStatus.ZERO_RESULTS) {
//           console.log(results[0].geometry.location);
//         }
//       } else {
//         console.log(results);
//         console.warn(
//           'Geocode was not successful for the following reason: ' + status
//         );
//       }
//     }
//   );
// }

Map.propTypes = {
  google: PropTypes.object.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyB0easIP3imiBe5ahVK8FUap2aoFhlYluU'
})(Map);
