import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { BASE_URL } from '../../../utils/api';
import { ReactComponent as PinIcon } from '../../../assets/icons/map-pin.svg';
import { ReactComponent as PhoneIcon } from '../../../assets/icons/phone.svg';
import { ReactComponent as MailIcon } from '../../../assets/icons/mail.svg';
import { ReactComponent as GlobeIcon } from '../../../assets/icons/globe.svg';
import PageTitle from '../../shared/PageTitle';
import PageContainer from '../../shared/PageContainer';
import Map from './Map';
import './ClinicPage.scss';

class ClinicPage extends React.Component {
  state = {
    clinic: {},
    isFetching: true
  };

  componentDidMount() {
    const { id } = this.props.match.params;

    fetch(`${BASE_URL}clinics`)
      .then(res => res.json())
      .then(res => {
        const clinic = res.data.find(clinic => clinic.id === +id);

        clinic &&
          this.setState({
            isFetching: false,
            clinic
          });
      });
  }

  createMarkup = data => ({ __html: data });

  render() {
    const { clinic, isFetching } = this.state;

    return (
      <PageContainer
        isFetching={isFetching}
        content={() => (
          <div className="container-clinic-page">
            <div className="container">
              <PageTitle
                belowTitle={() => (
                  <Fragment>
                    {Boolean(clinic.address) && (
                      <Fragment>
                        <PinIcon />
                        <span>{clinic.address}</span>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              >
                {clinic.title}
              </PageTitle>

              <div className="main-details">
                <div className="side">
                  <h3 className="description-title">Apie kliniką</h3>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={this.createMarkup(
                      clinic.description
                    )}
                  />
                  <div className="c-details">
                    <div className="contacts">
                      <h3>Kontaktai</h3>
                      {typeof clinic.phone === 'string' && (
                        <div className="contact-item">
                          <div className="icon-holder icon-phone">
                            <PhoneIcon />
                          </div>
                          <span>{clinic.phone}</span>
                        </div>
                      )}

                      {typeof clinic.phone !== 'string' &&
                        clinic.phone.map(({ phone }, key) => (
                          <div key={key} className="contact-item">
                            <div className="icon-holder icon-phone">
                              <PhoneIcon />
                            </div>
                            <span>{phone}</span>
                          </div>
                        ))}

                      {clinic.email && (
                        <div className="contact-item">
                          <div className="icon-holder">
                            <MailIcon />
                          </div>
                          <span>{clinic.email}</span>
                        </div>
                      )}

                      {clinic.url && (
                        <div className="contact-item">
                          <div className="icon-holder">
                            <GlobeIcon />
                          </div>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={clinic.url}
                          >
                            {clinic.url}
                          </a>
                        </div>
                      )}
                    </div>
                    {Boolean(Object.keys(clinic.working_hours).length) && (
                      <div className="work-hours">
                        <h3>Darbo valandos</h3>
                        <div className="work-hours-list">
                          {clinic.working_hours.map((h, i) => (
                            <div className="list-item" key={i}>
                              <div>{h.label}</div>
                              <div>{h.time}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="side">
                  {Boolean(clinic.image) && (
                    <img src={clinic.image.path} alt="" />
                  )}
                </div>
              </div>
            </div>
            <Map lat={clinic.map_longitude} lng={clinic.map_latitude} />
          </div>
        )}
      />
    );
  }
}

ClinicPage.propTypes = {
  match: PropTypes.object.isRequired
};

export default ClinicPage;
