import React from 'react';
import { groupBy } from 'lodash';
import PageContainer from '../../shared/PageContainer';
import PageTitle from '../../shared/PageTitle';
import ClinicsList from './ClinicsList';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/chevron-down.svg';
import { BASE_URL } from '../../../utils/api';

class ClinicsPage extends React.Component {
  state = {
    isFetching: true,
    clinics: [],
    citiesList: [],
    selectedCity: null
  };

  componentDidMount() {
    fetch(`${BASE_URL}clinics`)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          const clinics = [];
          const citiesList = [];

          const gropedClinics = groupBy(res.data, n => {
            if (n.city) {
              return n.city.id;
            }
          });

          Object.keys(gropedClinics).forEach((key, i) => {
            clinics[i] = {
              city: gropedClinics[key][0].city,
              list: gropedClinics[key]
            };
          });

          clinics.forEach(c => citiesList.push(c.city));

          this.setState({
            isFetching: false,
            clinics,
            citiesList
          });
        }
      });
  }

  render() {
    const { clinics, isFetching, citiesList, selectedCity } = this.state;

    const filteredClinics = selectedCity
      ? clinics.filter(clinicsGroup => clinicsGroup.city.id === +selectedCity)
      : clinics;

    return (
      <PageContainer
        isFetching={isFetching}
        content={() => (
          <div className="container">
            <PageTitle
              filter={() => (
                <div className="form-item type-select clinics-filter">
                  <select
                    onChange={e => {
                      this.setState({
                        selectedCity: e.target.value
                      });
                    }}
                  >
                    <option value="">Miestas</option>
                    {citiesList.map(c => (
                      <option value={c.id} key={c.id}>
                        {c.name}
                      </option>
                    ))}
                  </select>
                  <ArrowIcon />
                </div>
              )}
            >
              Klinikos
            </PageTitle>
            <ClinicsList clinics={filteredClinics} />
          </div>
        )}
      />
    );
  }
}

export default ClinicsPage;
