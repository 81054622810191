import React from 'react';
import PropTypes from 'prop-types';
import { BASE_URL } from '../../../utils/api';
import { ReactComponent as FbIcon } from '../../../assets/icons/fb.svg';
import './FbButton.scss';

class FbButton extends React.Component {
  state = {
    isFetching: true,
    facebookUrl: ''
  };

  componentDidMount() {
    fetch(`${BASE_URL}settings`)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          this.setState({
            isFetching: false,
            facebookUrl: res.data.facebookUrl
          });
        }
      });
  }
  render() {
    const { facebookUrl, isFetching } = this.state;

    if (isFetching) {
      return null;
    }

    return (
      <a
        href={facebookUrl}
        className="fb-button"
        target="_blank"
        rel="noreferrer noopener"
      >
        <FbIcon />
      </a>
    );
  }
}

FbButton.propTypes = {
  to: PropTypes.string.isRequired
};

export default FbButton;
