import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import PageContainer from '../../shared/PageContainer';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/chevron-down.svg';
import { BASE_URL } from '../../../utils/api';
import Title from './Title';
import DirectLinkTitle from './DirectLinkTitle';
import { TestConsumer } from '../../Test.context';
import LinkButton from '../../shared/LinkButton';
import RecommendationsList from './RecommendationsList';
import './TestResults.scss';

class TestResults extends React.Component {
  state = {
    barometerValue: 0,
    isFetching: true,
    data: {},
    testsResults: []
  };

  componentDidMount() {
    this.getTestsResults().then(() => {
      this.setState({
        isFetching: false
      });
      if (
        !this.props.directLink &&
        this.props.location.state &&
        this.props.location.state.type
      ) {
        this.showResults(this.props.location.state.type);
      } else if (!this.props.directLink && !this.props.location.state) {
        this.props.history.push('/');
      }
    });
  }

  getTestsResults = () => {
    return fetch(`${BASE_URL}test-results`)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          this.setState(
            {
              testsResults: res.data
            },
            () => Promise.resolve()
          );
        }
      });
  };

  showResults = type => {
    const data = this.state.testsResults.find(test => test.type === type);

    this.setState({
      data,
      showResults: true
    });
  };

  toggleTipContent = id => {
    const data = JSON.parse(JSON.stringify(this.state.data));

    const { related_researches } = data;

    related_researches.map(item => {
      if (item.id === id) {
        item.isOpen = !item.isOpen;
      }

      return item;
    });

    this.setState({ data });
  };

  repeatTest = changeStep => {
    this.props.history.push('/');
    changeStep(1);
  };

  createMarkup = html => ({
    __html: html
  });

  render() {
    const { data, isFetching, showResults } = this.state;
    const { directLink, initialValue, title, subtitle } = this.props;

    return (
      <PageContainer
        isFetching={isFetching}
        content={() => (
          <div className="container-test-results">
            <div className="container">
              {directLink ? (
                <TestConsumer>
                  {({ retrieveType }) => (
                    <DirectLinkTitle
                      fields={this.props.fields}
                      title={title}
                      subtitle={subtitle}
                      initialValue={initialValue}
                      onSubmit={form => {
                        const type = retrieveType(
                          Object.assign({}, form, initialValue)
                        );

                        this.showResults(type);
                      }}
                    />
                  )}
                </TestConsumer>
              ) : (
                <Title
                  data={data}
                  type={this.props.location.state.type}
                  onRepeatTest={this.repeatTest}
                />
              )}

              {showResults && (
                <Fragment>
                  {Boolean(data.main_researches.length) && (
                    <RecommendationsList list={data.main_researches} />
                  )}

                  {Boolean(data.tips.length) && (
                    <RecommendationsList
                      list={data.tips}
                      title="Patarimai:"
                      cols={2}
                    />
                  )}

                  {Boolean(data.related_researches.length) && (
                    <div className="tips-list">
                      <h2>Papildomi tyrimai:</h2>

                      <div className="tips-list-holder">
                        {data.related_researches.map(tip => (
                          <div
                            className={`list-item is-open-${tip.isOpen ||
                              false}`}
                            key={tip.id}
                          >
                            <div
                              className="tip-head"
                              onClick={() => this.toggleTipContent(tip.id)}
                            >
                              <div className="tip-head-text">{tip.title}</div>
                              <div className="toggle-btn">
                                <ArrowIcon />
                              </div>
                            </div>
                            <div
                              className="tip-content"
                              dangerouslySetInnerHTML={this.createMarkup(
                                tip.description
                              )}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </Fragment>
              )}
              <br />
              <LinkButton to={'/tyrimai-ir-prevencija/'}>
                Daugiau tyrimų
              </LinkButton>
            </div>
          </div>
        )}
      />
    );
  }
}

TestResults.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  directLink: PropTypes.bool,
  fields: PropTypes.array,
  initialValue: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default withRouter(TestResults);
