import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './PageTitle.scss';

const PageTitle = ({ children, belowTitle, border, filter }) => {
  const className = classNames('component-page-title', {
    'has-below-title': belowTitle,
    'no-border': border === false
  });

  return (
    <div className={className}>
      <div className="title-holder">
        <h1>
          <div>{children}</div>
          <span className="below-title">{belowTitle && belowTitle()}</span>
        </h1>
        {filter && filter()}
      </div>
    </div>
  );
};

PageTitle.defaultProps = {
  border: true
};

PageTitle.propTypes = {
  children: PropTypes.node,
  belowTitle: PropTypes.func,
  filter: PropTypes.func,
  border: PropTypes.bool
};

export default PageTitle;
