import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ReactComponent as PenIcon } from '../../../assets/icons/pen.svg';
import { ReactComponent as BurgerIcon } from '../../../assets/icons/burger.svg';
import { ReactComponent as NextIcon } from '../../../assets/icons/arrow-thin.svg';

import './Button.scss';

const Button = ({ type, onClick, children, burger, block, simple, ghost }) => {
  const getIcon = () => {
    if (simple) {
      return null;
    }
    if (burger) {
      return <BurgerIcon />;
    }

    if (ghost) {
      return <NextIcon />;
    }

    return <PenIcon />;
  };
  const className = classNames('btn', {
    'btn-burger': burger,
    'btn-simple': simple,
    'btn-block': block,
    'btn-ghost': ghost
  });

  return (
    <button type={type} onClick={onClick} className={className}>
      {getIcon()}
      <span>{children}</span>
    </button>
  );
};

Button.defaultProps = {
  type: 'submit'
};

Button.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  burger: PropTypes.bool,
  simple: PropTypes.bool,
  block: PropTypes.bool,
  ghost: PropTypes.bool
};

export default Button;
