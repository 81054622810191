import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/images/roche-logo.svg';
import './Footer.scss';

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="container">
          <div className="footer-content">
            <div className="side">
              <div className="note">
                © 2018 tikrinu.lt. Visos teisės saugomos.
              </div>
              <nav>
                <Link to="/privatumo-politika">Privatumo politika</Link>
                <Link to="/slapukai">Slapukai</Link>
                <Link to="/teisine-informacija">Teisinė informacija</Link>
              </nav>
            </div>
            <div className="logo-holder">
              <a
                href="https://www.roche.lt/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src={logo} alt="roche" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
