import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow.svg';
import { ReactComponent as ArrowRightIcon } from '../../../assets/icons/arrow-right.svg';
import { ReactComponent as PinIcon } from '../../../assets/icons/map-pin-2.svg';
import './LinkButton.scss';

const LinkButton = ({ to, children, secondary, simple }) => (
  <div className="component-link-button">
    <Link to={to}>
      {simple ? (
        <span className="arrow-simple-icon">
          <ArrowRightIcon />
        </span>
      ) : (
        <span className="arrow-icon">
          {secondary ? <PinIcon /> : <ArrowIcon />}
        </span>
      )}

      {Boolean(children) && <span className="link-text">{children}</span>}
    </Link>
  </div>
);

LinkButton.defaultProps = {
  to: '/'
};

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node,
  secondary: PropTypes.bool
};

export default LinkButton;
