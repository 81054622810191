import React, { Component, Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from './core/Header';
import Footer from './core/Footer';
import Cookies from './core/Cookies';
import LandingPage from './containers/LandingPage';
import SearchPage from './containers/SearchPage';
import ClinicsPage from './containers/ClinicsPage';
import ClinicPage from './containers/ClinicPage';
import ResearchesPage from './containers/ResearchesPage';
import ResearchPage from './containers/ResearchPage';
import TextPage from './containers/TextPage';
import TestResults from './containers/TestResults';
import TestProvider from './Test.context';
import { BASE_URL } from '../utils/api';
import '../styles/main.scss';

class App extends Component {
  state = {
    isFetching: true,
    directTestPage: [
      {
        path: '/laukiuosi',
        title: 'Laukiuosi',
        subtitle: 'Tik dėl savęs pačios pasitikrink!',
        fields: ['stage2'],
        initialValue: {
          stage1: 0,
          age: 22
        }
      },
      {
        path: '/noriu-pastoti',
        title: 'Noriu pastoti',
        subtitle: 'Tik dėl savęs pačios pasitikrink!',
        fields: ['age'],
        initialValue: {
          stage1: 1
        }
      },
      {
        path: '/esu-sveika',
        title: 'Esu sveika',
        subtitle: 'Tik dėl savęs pačios pasitikrink!',
        fields: ['age', 'stage1', 'stage2'],
        initialValue: {}
      },
      {
        path: '/man-menopauze',
        title: 'Man menopauzė',
        subtitle: 'Tik dėl savęs pačios pasitikrink!',
        fields: [],
        initialValue: {
          age: 50
        }
      }
    ]
  };

  componentDidMount() {
    fetch(`${BASE_URL}settings`)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          const pages = res.data.pages;
          const links = JSON.parse(JSON.stringify(this.state.directTestPage));

          links.map((l, i) => {
            l.subtitle = pages[i].subtitle;
            l.title = pages[i].title;
          });

          this.setState({
            directTestPage: links,
            isFetching: false
          });
        }
      });
  }

  render() {
    return (
      <TestProvider>
        {!this.state.isFetching && (
          <Fragment>
            <Header />
            <Switch>
              <Route exact path="/" component={LandingPage} />

              {this.state.directTestPage.map(page => (
                <Route
                  key={page.path}
                  exact
                  path={page.path}
                  render={props => (
                    <TestResults
                      {...props}
                      key={page.path}
                      fields={page.fields}
                      initialValue={page.initialValue}
                      title={page.title}
                      subtitle={page.subtitle}
                      directLink
                    />
                  )}
                />
              ))}

              <Route exact path="/paieska" component={SearchPage} />
              <Route exact path="/rezultatai" component={TestResults} />
              <Route exact path="/klinikos" component={ClinicsPage} />
              <Route exact path="/klinikos/:id/:name?" component={ClinicPage} />
              <Route
                exact
                path="/(tyrimai-ir-prevencija|researches-and-preventions)"
                component={ResearchesPage}
              />

              <Route
                exact
                path="/(tyrimai-ir-prevencija|researches-and-preventions)/:id/:subgroup/:name?"
                component={ResearchPage}
              />

              <Route
                exact
                path="/(privatumo-politika|privacy-policy)"
                render={props => (
                  <TextPage
                    {...props}
                    systemName="privacy-policy"
                    key="privacy-policy"
                  />
                )}
              />
              <Route
                exact
                path="/(slapukai|cookies)"
                render={props => (
                  <TextPage {...props} systemName="cookies" key="cookies" />
                )}
              />
              <Route
                exact
                path="/(teisine-informacija|legal-information)"
                render={props => (
                  <TextPage
                    {...props}
                    systemName="legal-information"
                    key="legal-information"
                  />
                )}
              />
              <Route
                exact
                path="/(apie|about)"
                render={props => (
                  <TextPage {...props} systemName="about" key="about" />
                )}
              />
            </Switch>
            <Footer />
            <Cookies />
          </Fragment>
        )}
      </TestProvider>
    );
  }
}

export default App;
