import React, { Fragment } from 'react';
import Hero from './Hero';
import FeaturesList from './FeaturesList';
import StatsCta from './StatsCta';

class LandingPage extends React.Component {
  render() {
    return (
      <Fragment>
        <Hero />
        <FeaturesList />
        <StatsCta />
      </Fragment>
    );
  }
}

export default LandingPage;
