import React from 'react';
import PropTypes from 'prop-types';
import TextField from '../../../shared/TextField';

class DirectLinkTitle extends React.Component {
  state = {
    age: null,
    stage1: null,
    stage2: null
  };

  componentDidMount() {
    if (!this.props.fields.length) {
      this.props.onSubmit({});
    }
  }

  submitForm() {
    this.props.onSubmit(this.state);
  }

  updateForm = value => {
    const { fields } = this.props;

    this.setState(Object.assign({}, this.state, value), () => {
      const { age, stage1, stage2 } = this.state;

      if (
        ((fields.includes('age') && age > 9) || !fields.includes('age')) &&
        fields.length < 3 &&
        !fields.filter(f => this.state[f] === null).length
      ) {
        this.submitForm();
      } else if (fields.length === 3) {
        if (age > 9 && ((stage1 !== 0 && stage1 !== null) || stage2 !== null)) {
          this.submitForm();
        }
      }
    });
  };

  stage1Values = ['Taip', 'Ne', 'Planuoju'];
  stage2Values = ['I', 'II', 'III'];

  render() {
    const { stage1, stage2 } = this.state;
    const { fields, title, subtitle } = this.props;

    return (
      <div className="test-results-title direct-link-title">
        <div className="side">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
        {Boolean(fields.length) && (
          <div className="side">
            <div className="filters-bar">
              {fields.includes('age') && (
                <div className="col">
                  <label>Kiek tau metų?</label>
                  <TextField
                    placeholder="Įvesk savo amžių"
                    regular
                    type="number"
                    onChange={e =>
                      this.updateForm({
                        age: e.target.value
                      })
                    }
                  />
                </div>
              )}

              {fields.includes('stage1') && (
                <div className="col">
                  <label>Ar laukiesi?</label>
                  <div className="radio-buttons-group">
                    {this.stage1Values.map((name, i) => (
                      <div
                        className={`radio-button active-${stage1 === i}`}
                        onClick={() => {
                          const value = { stage1: i };

                          if (i !== 0) {
                            value.stage2 = null;
                          }

                          this.updateForm(value);
                        }}
                        key={i}
                      >
                        {name}
                      </div>
                    ))}
                  </div>
                </div>
              )}

              {((fields.includes('stage1') &&
                fields.includes('stage2') &&
                stage1 === 0) ||
                (!fields.includes('stage1') && fields.includes('stage2'))) && (
                <div className="col">
                  <label>Kuris nėštumo trimestras?</label>
                  <div className="radio-buttons-group">
                    {this.stage2Values.map((name, i) => (
                      <div
                        className={`radio-button active-${stage2 === i}`}
                        onClick={() => {
                          this.updateForm({ stage2: i, stage1: 0 });
                        }}
                        key={i}
                      >
                        {name}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

DirectLinkTitle.propTypes = {
  fields: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default DirectLinkTitle;
