import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg';
import { ReactComponent as FindIcon } from '../../../../assets/icons/find.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/close.svg';
import enhanceWithClickOutside from 'react-click-outside';
import './Search.scss';

class Search extends React.Component {
  handleClickOutside = () => {
    this.props.onCloseSearch();
  };

  render() {
    const {
      isOpen,
      toggleSearch,
      onSubmit,
      onChange,
      value,
      innerRef
    } = this.props;

    return (
      <div className="component-search-input">
        <div className={`search-input-holder  is-open-${isOpen}`}>
          <form
            onSubmit={e => {
              e.preventDefault();
              onSubmit();
            }}
          >
            <div className="find-icon-holder">
              <FindIcon />
            </div>
            <input
              type="text"
              placeholder="Ieškoti"
              onChange={onChange}
              value={value}
              ref={innerRef}
            />
          </form>
        </div>
        <div className="btn-search" onClick={toggleSearch}>
          {isOpen ? <CloseIcon /> : <SearchIcon />}
        </div>
      </div>
    );
  }
}

Search.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleSearch: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  innerRef: PropTypes.object.isRequired,
  onCloseSearch: PropTypes.func.isRequired
};

export default enhanceWithClickOutside(Search);
