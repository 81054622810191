import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { ReactComponent as PinIcon } from '../../../../assets/icons/map-pin.svg';
import { ReactComponent as PhoneIcon } from '../../../../assets/icons/phone.svg';
import LinkButton from '../../../shared/LinkButton';
import urlBuilder from '../../../../utils/urlBuilder';
import './ClinicsList.scss';

const getClinicUrl = (id, name) => {
  return `${id}/${urlBuilder(name)}`;
};

const ClinicsList = ({ clinics }) => {
  return (
    <div className="component-clinics-list">
      {clinics.map(cg => (
        <div className="clinics-group" key={cg.city.name}>
          <h2>{cg.city.name}</h2>
          <div className="clinics-group-items">
            {cg.list.map(c => (
              <div className="clinics-group-item" key={c.id}>
                <Link to={`/klinikos/${getClinicUrl(c.id, c.title)}`} />
                <div className="logo-holder">
                  {Boolean(c.logo) && <img src={c.logo.path} alt="" />}
                </div>
                <div className="main-details">
                  <div className="name">{c.title}</div>
                  <div className="contact-details">
                    {Boolean(c.address) && (
                      <div className="contact-details-item">
                        <PinIcon /> {c.address}
                      </div>
                    )}
                    <MediaQuery query="(min-width: 1025px)">
                      {Boolean(c.phone) && (
                        <div className="contact-details-item">
                          <PhoneIcon />{' '}
                          {typeof c.phone === 'string'
                            ? c.phone
                            : c.phone.map(({ phone }) => phone).join(', ')}
                        </div>
                      )}
                    </MediaQuery>
                  </div>
                </div>
                <MediaQuery query="(min-width: 769px)">
                  <LinkButton to={`/klinikos/${getClinicUrl(c.id, c.title)}`} />
                </MediaQuery>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

ClinicsList.propTypes = {
  clinics: PropTypes.array.isRequired
};

export default ClinicsList;
