import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { BASE_URL } from '../../../utils/api';
import PageContainer from '../../shared/PageContainer';
import PageTitle from '../../shared/PageTitle';
import urlBuilder from '../../../utils/urlBuilder';
import ResultsItem from './ResultsItem';
import './SearchPage.scss';

class SearchPage extends React.Component {
  state = {
    isFetching: true,
    searchPhrase: '',
    results: {}
  };

  componentDidMount() {
    this.getResults();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      this.getResults();
    }
  }

  getResults = () => {
    const searchPhrase =
      this.props.location.search &&
      this.props.location.search.replace('?=', '');

    if (searchPhrase) {
      fetch(`${BASE_URL}search/${searchPhrase}`)
        .then(res => res.json())
        .then(res => {
          if (res.success) {
            this.setState({
              searchPhrase,
              results: res.data,
              isFetching: false
            });
          }
        });
    } else {
      this.history.push('/');
    }
  };

  render() {
    const { searchPhrase, isFetching, results } = this.state;
    const { history } = this.props;

    return (
      <PageContainer
        isFetching={isFetching}
        content={() => (
          <div className="container container-search-page">
            <PageTitle>Paieška</PageTitle>
            <h2 className="search-phrase">{decodeURI(searchPhrase)}</h2>
            <div className="search-results">
              {Boolean(results['clinics'].length) &&
                results['clinics'].map((r, i) => (
                  <ResultsItem
                    key={i}
                    title={r.title}
                    description={r.description}
                    onClick={() => {
                      history.push(`/klinikos/${r.id}/${urlBuilder(r.title)}`);
                    }}
                  />
                ))}

              {Boolean(results['research-and-prevention'].length) &&
                results['research-and-prevention'].map((r, i) => (
                  <ResultsItem
                    key={i}
                    title={r.title}
                    description={r.description}
                    onClick={() => {
                      history.push(
                        `/tyrimai-ir-prevencija/${r.id}/${urlBuilder(r.title)}`
                      );
                    }}
                  />
                ))}

              {Boolean(results['test-results'].length) &&
                results['test-results'].map((r, i) => (
                  <ResultsItem
                    key={i}
                    title={r.title}
                    description={r.description}
                    onClick={() => {
                      history.push({
                        pathname: '/rezultatai',
                        state: { id: r.id }
                      });
                    }}
                  />
                ))}

              {Boolean(results['text-page'].length) &&
                results['text-page'].map((r, i) => (
                  <ResultsItem
                    key={i}
                    title={r.title}
                    description={r.text}
                    onClick={() => {
                      history.push(`/${r.name}`);
                    }}
                  />
                ))}

              {!results['text-page'].length &&
                !results['test-results'].length &&
                !results['clinics'].length &&
                !results['research-and-prevention'].length && (
                  <div>
                    Apgailestaujame, tačiau svetainėje neaptikome ieškomo
                    raktažodžio (-ių).
                  </div>
                )}
            </div>
          </div>
        )}
      />
    );
  }
}

SearchPage.propTypes = {
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(SearchPage);
