import React from 'react';
import { BASE_URL } from '../../../utils/api';
import ReseachList from './ReseachList';
import TextPage from '../TextPage';

import './ResearchesPage.scss';

class ResearchesPage extends React.Component {
  state = {
    isFetching: true,
    data: {}
  };

  componentDidMount() {
    fetch(`${BASE_URL}researches-and-preventions`)
      .then(res => res.json())
      .then(res => {
        if (res.success) {
          this.setState({
            isFetching: false,
            data: res.data
          });
        }
      });
  }

  render() {
    const { isFetching, data } = this.state;

    return (
      <TextPage
        systemName="researches-and-preventions"
        key="researches-and-preventions"
      >
        <ReseachList list={data} isFetching={isFetching} />
      </TextPage>
    );
  }
}

export default ResearchesPage;
